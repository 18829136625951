import { AppPropsWithLayout } from '@client/layouts';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { appWithTranslation } from 'next-i18next';
import Head from 'next/head';

import Modal from '@ebay/nice-modal-react';
import ReactQuery from '@client/components/ReactQuery';
import Toast from '@client/components/Toast';

import '../public/css/bootstrap.min.css';
import '../public/css/fontawesome.min.css';
import '../public/css/style.css';
import SessionProvider from '@client/components/SessionProvider';

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);

  const router = useRouter();

  // const [loader, setLoader] = useState(true);

  const authorization = useCallback(() => {
    // setLoader(true);

    if (pageProps.protected) {
      if (pageProps.session == null) {
        return router.push('/');
      }
    }

    // setLoader(false);
  }, [pageProps.protected, pageProps.session, router]);

  useEffect(() => {
    authorization();
  }, [authorization]);

  return (
    <>
      <Head>
        <title>KluwyPay - Backoffice</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        {/* <link
          rel="shortcut icon"
          type="image/png"
          href="./images/favicon.png"
        /> */}
      </Head>

      <ReactQuery>
        <SessionProvider session={pageProps.session}>
          <Toast />
          <Modal.Provider>
            {getLayout(<Component {...pageProps} />)}
          </Modal.Provider>
        </SessionProvider>
      </ReactQuery>
    </>
  );
}

export default appWithTranslation(MyApp);
