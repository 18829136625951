import { Toaster, ToastBar } from 'react-hot-toast';

interface Props {}

const config = {
  style: {
    border: '1px solid transparent',
    borderRadius: '6px',
    fontSize: '0.875rem',
    opacity: 0.9,
    padding: '12px',
  },
  iconTheme: {
    primary: '#fff',
    secondary: '#333',
  },
  success: {
    style: {
      backgroundColor: '#333',
      borderColor: '#333',
      color: '#fff',
    },
    iconTheme: {
      primary: '#fff',
      secondary: '#333',
    },
  },
  error: {
    iconTheme: {
      primary: '#fff',
      secondary: '#d90102',
    },
    style: {
      backgroundColor: '#d90102',
      borderColor: '#d90102',
      color: '#fff',
    },
  },
};

const Toast: React.FC<Props> = () => {
  return (
    <Toaster position="top-right" toastOptions={config}>
      {(t) => (
        <ToastBar toast={t} position="top-right">
          {({ icon, message }) => (
            <>
              {icon}
              <div>{message}</div>
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};

export default Toast;
