import { createContext, useContext, useState, useEffect } from 'react';
import User from '@client/entities/User';

interface Props {
  children: React.ReactNode;
  session: User;
}

const SessionContext = createContext<any>(null);
export const useSession = () => useContext(SessionContext);

const SessionProvider: React.FC<Props> = ({ children, session }) => {
  const user = new User();
  const [state, setState] = useState(user);

  const setSession = (data: any) => {
    setState((prev: any) => ({
      ...prev,
      data,
    }));
  };

  useEffect(() => {
    const data = session || user;

    setState(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  return (
    <SessionContext.Provider value={{ ...state, setSession }}>
      {children}
    </SessionContext.Provider>
  );
};

export default SessionProvider;
