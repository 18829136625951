import { serialize, deserialize } from 'cerialize';

export default class User {
  @serialize
  @deserialize
  public id: string = '';

  @serialize
  @deserialize
  public email: string = '';

  @serialize
  @deserialize
  public fullname: string = '';

  @serialize
  @deserialize
  public role: string = '';

  @serialize
  @deserialize
  public createdTime: string = '';

  @serialize
  @deserialize
  public updatedTime: string = '';

  @serialize
  @deserialize
  public enabled: boolean = false;

  @serialize
  @deserialize
  public token: string = '';

  @serialize
  @deserialize
  public password: string = '';

  // public static OnSerialized(instance: User, json: any): void {
  //   if (instance.enabled === 0) {
  //     json.enabled = false;
  //   } else {
  //     json.enabled = true;
  //   }
  // }
}
